<template>
  <div class="wrapper">
    <navbar-component/>
    <div class="container-fluid py-4">
      <div class="px-xl-4">
        <router-view />
      </div>
    </div>
  </div>
</template>
<style></style>
<script>
import NavbarComponent from "../../components/facility/navbar.component";
export default {
  components: {
    NavbarComponent,
  },
    data() {
        return {
            airport: {}
        };
    },
  mounted() {


  },
};
</script>
