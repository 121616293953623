<template>
  <div>
    <div class="px-3 pt-2 text-right">
      <span class="mr-2">
        (
        <strong
        class="cursor-pointer"
        @click="changeLanguage('ja')"
        :class="$i18n.locale == 'ja' ? 'text-primary' : ''"
        >
          日
        </strong>
        |
        <strong
        class="cursor-pointer"
        @click="changeLanguage('en')"
        :class="$i18n.locale == 'en' ? 'text-primary' : ''"
        >
        EN
        </strong>
        )
      </span>
      <template v-if="airportReady">
        {{
          this.$i18n.locale == "en"
            ? airport.airport_name_en
            : airport.airport_name_jp
        }}
      </template>
      <template v-else>
        ---
      </template>
    </div>
    <nav class="navbar navbar-expand border-bottom pt-0" id="facility-navbar">
      <router-link to="/facility/dashboard" class="navbar-brand">
        {{ $t("facility.navbar.booking-dashboard") }}
      </router-link>
      <div class="ml-auto">
        <ul class="navbar-nav">
          <li class="nav-item">
            <router-link to="/facility/search" class="nav-link">
              {{ $t("facility.navbar.search") }}
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/facility/users" class="nav-link" v-if="hasEditFee()">
                {{ $t("facility.navbar.user-search") }}
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/facility/inventory" class="nav-link">
              {{ $t("facility.navbar.inventory") }}
            </router-link>
          </li>
            <li class="nav-item">
                <router-link to="/facility/flight-cancellations" class="nav-link">
                    {{ $t("facility.navbar.flight-cancellation") }}
                </router-link>
            </li>

            <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {{ $t("facility.navbar.fees") }}
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                    <router-link tag="a" to="/facility/fees" class="dropdown-item" v-if="hasEditFee()">
                        {{ $t("facility.navbar.reservation-fee") }}
                    </router-link>
                    <router-link tag="a" to="/facility/coupons" class="dropdown-item" v-if="hasEditBooking()">
                        {{ $t("facility.navbar.coupons") }}
                    </router-link>
                    <router-link tag="a" to="/facility/reservation-calc-setting" class="dropdown-item" v-if="hasUpdateReservationCalcSetting()">
                        {{ $t("facility.navbar.reservation-calc-setting") }}
                    </router-link>
                </div>
            </li>
          <li class="nav-item">
              <router-link to="/facility/sales" class="nav-link">
                  {{ $t("facility.navbar.sales") }}
              </router-link>
          </li>
          <li class="nav-item" v-if="hasAccount()">
            <router-link to="/facility/account" class="nav-link">
              {{ $t("facility.navbar.account") }}
            </router-link>
          </li>
          <li class="nav-item" v-if="hasAccount()">
            <router-link to="/facility/setting" class="nav-link">
              {{ $t("facility.navbar.setting") }}
            </router-link>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="javascript:void(0)" @click="logout$">
              {{ $t("facility.navbar.logoff") }}
            </a>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script>
import { facilitySettingProfileService } from "@/services/facility/setting/profile.service";
import config from "@/config/app.config";
import { EventBus } from "@/event-bus";
export default {
  data() {
    return {
      airport: {},
      airportReady: false,
    };
  },
  async mounted() {
    this.getAirportData(config.airport_id);
  },
  methods: {
    async getAirportData(id) {
      this.airportReady = false;
      const response = await facilitySettingProfileService.show(id);
      this.airport = response.data;
      this.airportReady = true;
    },
    changeLanguage(lang) {
      if(lang != this.$i18n.locale) {
        this.$i18n.locale = lang
        EventBus.$emit('changeLocal');
      }
    },
    async logout$() {
      await this.$storage.remove("auth");
      await localStorage.removeItem("search-filter");
      await this.$router.push({ name: "facility-login" });
    },
  },
};
</script>
<style>
#facility-navbar {
  border-width: 2px !important;
  border-color: #2524222b !important;
}
.navbar-brand {
  font-weight: 600;
  font-size: 24px;
  color: #252422 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.nav-link {
  font-weight: 500;
  color: #555555 !important;
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.nav-item:last-child .nav-link {
  padding-right: 0 !important;
}
</style>
